<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!--Filter: Concessionária-->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Concessionária</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :options="dealershipsOptions"
          >
            <span slot="no-options">
              Desculpe, nenhuma opção correspondente.
            </span>
          </v-select>
        </b-col>

        <!--Filter: Perfil-->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Perfil</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :options="profilesOptions"
          >
            <span slot="no-options">
              Desculpe, nenhuma opção correspondente.
            </span>
          </v-select>
        </b-col>

        <!--Filter: Status-->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :options="statusOptions"
          >
            <span slot="no-options">
              Desculpe, nenhuma opção correspondente.
            </span>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,

    vSelect,
  },
  props: {
    dealershipsOptions: {
      type: Array,
      required: true,
    },
    profilesOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
